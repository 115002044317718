<template>
  <div>
    <v-progress-linear
      class="loader"
      indeterminate
      v-show="isLoading"
      color="loaderColor"
    />
    <v-layout
      wrap
      id="report-details-wrapper"
      v-bind:class="isMobile && 'mobile'"
    >
      <v-flex xs12 id="report-header-wrapper">
        <v-btn @click="goBack" icon text>
          <IconBox
            :tooltip="$t('common.back')"
            :styles="{ 'font-size': '20px' }"
            color="iconButton"
          >
            stem-arrowBack
          </IconBox>
        </v-btn>
        <span class="subheading">{{ report ? report.name : null }}</span>
      </v-flex>
      <v-flex xs12 id="report-iframe-wrapper">
        <vue-friendly-iframe
          allowfullscreen
          frameborder="0"
          :src="report ? encodeURI(report.path) : ''"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import ReportService from "@/services/ReportService";
import { mapGetters } from "vuex";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      report: null,
      isLoading: false,
    };
  },
  mounted() {
    this.isLoading = true;
    ReportService.GetReportDetails(this.id)
      .then((response) => {
        this.report = response;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    goBack() {
      // go(-1) doesn't work well with iframe on Firefox
      this.$router.push({ name: "reports" });
    },
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
  },
};
</script>

<style>
.loader {
  margin: -12px -12px 0 -12px;
  width: auto;
}
#report-details-wrapper:not(.mobile) .vue-friendly-iframe {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

#report-details-wrapper:not(.mobile) .vue-friendly-iframe iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

#report-details-wrapper.mobile {
  height: calc(100vh - 88px);
  flex-direction: column;
}

#report-details-wrapper.mobile #report-iframe-wrapper {
  flex: 1;
  display: flex;
}

#report-details-wrapper.mobile #report-header-wrapper {
  flex-basis: auto;
  flex-direction: row;
  display: flex;
}

#report-details-wrapper.mobile .vue-friendly-iframe {
  flex: 1;
  position: relative;
  display: flex;
}

#report-details-wrapper.mobile .vue-friendly-iframe iframe {
  flex: 1;
}
</style>
